import listOfCloudPcs from "./cloudPcs.json";
var graph = require("@microsoft/microsoft-graph-client");

function getAuthenticatedClient(accessToken: string) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  //   https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http
  const user = await client.api("/me").select("displayName").get();

  console.log(`user`, user);
  return user;
}

export async function getCloudPcs(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/virtualendpoint-list-cloudpcs?view=graph-rest-beta&tabs=http
  const cloudPcs = await client
    .api("/deviceManagement/virtualEndpoint/cloudPCs")
    // .select("displayName")
    .version("beta")
    .get();

  return cloudPcs;
}

export async function getCloudPcLastLogin(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  console.log(`id`, id);
  // https://docs.microsoft.com/en-us/graph/api/cloudpc-get?view=graph-rest-beta&tabs=http
  const cloudPcs = await client
    .api(`/deviceManagement/virtualEndpoint/cloudPCs/${id}`)
    .select("lastloginResult")
    .version("beta")
    .get();

  return cloudPcs;
}

export async function reprovisionCloudPc(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/cloudpc-reprovision?view=graph-rest-beta&tabs=http
  const result = await client
    .api(`/deviceManagement/virtualEndpoint/cloudPCs/${id}/reprovision`)
    .version("beta")
    .post();

  return id;
}

export async function endCloudPcGracePeriod(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/cloudpc-endgraceperiod?view=graph-rest-beta&tabs=http
  const result = await client
    .api(`/deviceManagement/virtualEndpoint/cloudPCs/${id}/endGracePeriod`)
    .version("beta")
    .post();

  return id;
}

export async function rebootCloudPc(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/cloudpc-reboot?view=graph-rest-beta&tabs=http
  await client
    .api(`/deviceManagement/virtualEndpoint/cloudPCs/${id}/reboot`)
    .version("beta")
    .post();

  return id;
}

// Must be use `manageDeviceId` not `id`
export async function reprovisionCloudPcsWithManageIds(
  accessToken: string,
  bulkReprovisionCloudPc: { managedDeviceIds: [string] }
) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/manageddevice-bulkreprovisioncloudpc?view=graph-rest-beta&tabs=javascript
  const result = await client
    .api(`/deviceManagement/managedDevices/bulkReprovisionCloudPc`)
    .version("beta")
    .post(bulkReprovisionCloudPc);

  return result;
}

export async function troubleshootCloudPcs(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  //https://docs.microsoft.com/en-us/graph/api/cloudpc-troubleshoot?view=graph-rest-beta&tabs=http
  const result = await client
    .api(`/deviceManagement/virtualEndpoint/cloudPCs/${id}/troubleshoot`)
    .version("beta")
    .post();

  return id;
}

export async function auditCloudPcs(accessToken: string, id: string) {
  const client = getAuthenticatedClient(accessToken);

  // https://docs.microsoft.com/en-us/graph/api/virtualendpoint-list-auditevents?view=graph-rest-beta&tabs=http
  const result = await client
    .api(`/deviceManagement/virtualEndpoint/auditEvents`)
    .filter(`resources/any(c:c/resourceId+eq+'${id}')`)
    .version("beta")
    .get();

  console.log(`result from getlist`, result);
  console.log(`id`, id);
  return result;
}
