import React from "react";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "reactstrap";
import { ACTION_TYPE } from "../utils/constants";

const { SearchBar } = Search;

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

type Props = {
  data: Object,
  keyField: string,
  columns: Array<Object>,
  onSelect?: (row, isSelect) => void,
  onSelectAll?: (rows, isSelect) => void,
  onAction?: (string) => void,
  disableSelect: boolean,
  disableActionButtons: boolean,
  disableSearch: boolean,
};

const Table = ({
  data,
  keyField,
  columns,
  onSelect,
  onSelectAll,
  onAction,
  disableSelect,
  disableActionButtons,
  disableSearch,
}: Props) => {
  const renderButtons = () => {
    return disableActionButtons ? undefined : (
      <>
        <Button
          className="btn-round btn-icon ml-3"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            onAction(ACTION_TYPE.REBOOT);
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-power-off" />
          </span>
          <span className="btn-inner--text">Reboot</span>
        </Button>
        <Button
          className="btn-round btn-icon"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            !!onAction && onAction(ACTION_TYPE.REPROVISION);
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-recycle" />
          </span>
          <span className="btn-inner--text">Reprovision</span>
        </Button>
        <Button
          className="btn-round btn-icon"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            !!onAction && onAction(ACTION_TYPE.END_GRACE_PERIOD);
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-trash" />
          </span>
          <span className="btn-inner--text">End Grace Period</span>
        </Button>
        <Button
          className="btn-round btn-icon"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            !!onAction && onAction(ACTION_TYPE.TROUBLESHOOT);
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-band-aid" />
          </span>
          <span className="btn-inner--text">Troubleshoot</span>
        </Button>
        <Button
          className="btn-round btn-icon"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            !!onAction && onAction(ACTION_TYPE.AUDIT);
          }}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-band-aid" />
          </span>
          <span className="btn-inner--text">Audit</span>
        </Button>
      </>
    );
  };

  const selectRow = disableSelect
    ? undefined
    : {
        mode: "checkbox",
        onSelect: (row, isSelect, rowIndex, e) => {
          !!onSelect && onSelect(row, isSelect);
        },

        onSelectAll: (isSelect, rows, e) => {
          !!onSelectAll && onSelectAll(rows, isSelect);
        },
      };

  const searchField = (props) =>
    disableSearch ? undefined : (
      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
        <label>
          Search:
          <SearchBar
            // className="form-control-sm"
            // placeholder=""
            {...props.searchProps}
          />
        </label>
        {renderButtons()}
      </div>
    );
  return (
    <ToolkitProvider data={data} keyField={keyField} columns={columns} search>
      {(props) => (
        <div className="py-4 table-responsive">
          {/* {searchField(props)} */}
          <div
            id="datatable-basic_filter"
            className="dataTables_filter px-4 pb-1"
          >
            <label>
              Search:
              <SearchBar
                className="form-control-sm"
                placeholder=""
                {...props.searchProps}
              />
            </label>
            {renderButtons()}
          </div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4
            pagination={pagination}
            bordered={false}
            selectRow={selectRow}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default Table;
