import React from "react";
import { Container, Row, Col } from "reactstrap";

class LandingFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                  © {new Date().getFullYear()} Cloud PC Management
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default LandingFooter;
