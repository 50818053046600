import React from "react";

import Chart from "chart.js";
import { Line } from "react-chartjs-2";
// reactstrap components
import { Card, CardHeader, CardBody } from "reactstrap";

import { chartOptions, parseOptions } from "../../variables/charts.js";

class LineGraph extends React.Component {
  //   const LineGraph = (props) => {
  constructor(props) {
    super(props);
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    const { title, subTitle, data, options } = this.props;
    return (
      <>
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <CardHeader className="bg-transparent">
              <h6 className="text-muted text-uppercase ls-1 mb-1">{title}</h6>
              {subTitle && <h2 className="h3 mb-0">{subTitle}</h2>}
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Line
                  data={data}
                  options={options}
                  id="chart-sales"
                  className="chart-canvas"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default LineGraph;
