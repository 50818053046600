import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand, Collapse, Navbar, Container, Row, Col } from "reactstrap";

class AdminNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggle = () => {
    if (this.state.width < 992) {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
  };

  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <img
                alt="logo"
                src={require("../../assets/img/brand/logo_white.png")}
              />
            </NavbarBrand>

            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="navbar-custom-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/" onClick={this.toggle}>
                      <img
                        alt="logo"
                        src={require("../../assets/img/brand/logo.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                      onClick={this.toggle}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <hr className="d-lg-none" />
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
