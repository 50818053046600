import React, { useState, useEffect } from "react";

import { Button, Modal, Card, CardHeader, Row, Col } from "reactstrap";
import AuthContext from "../../../src/auth/AuthContext";
import {
  getCloudPcs,
  rebootCloudPc,
  getCloudPcLastLogin,
  reprovisionCloudPcsWithManageIds,
  endCloudPcGracePeriod,
  troubleshootCloudPcs,
  auditCloudPcs,
  reprovisionCloudPc,
} from "../../auth/GraphService";
import Table from "./Table";
import { ACTION_TYPE } from "../utils/constants";
import map from "lodash/map";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import Loader from "../../Loader";
import DotLoader from "react-spinners/DotLoader";
import { RESULT_STATUS_TYPE } from "../utils/constants";
import classnames from "classnames";

type Props = {
  title: string,
  tableData: [],
};

const ManagementTable = (props: Props) => {
  const { title, tableData } = props;
  const auth = React.useContext(AuthContext);
  const [selectedIdSet, setSelectedIdSet] = useState(new Set());
  const [showModal, setShowModal] = useState(false);
  const [actionName, setActionNameState] = useState("");

  const [wasActionCalled, setWasActionCalled] = useState(false);
  const [actionResponse, setActionResponse] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  const refreshAllCloudPcs = async () => {
    await auth.callGetCloudPcsAndShowLoader(auth.getAccessToken());
  };

  const getSelectedIdsAndNames = () => {
    const results = keyBy(actionResponse, "value");
    const tableData = [];
    selectedIdSet.forEach((row) => {
      let action = RESULT_STATUS_TYPE.WAITING;
      if (wasActionCalled) {
        if (isEmpty(results)) {
          action = RESULT_STATUS_TYPE.LOADING;
        } else {
          action = results?.[row.id]?.status;
        }
      }
      tableData.push({ ...row, action });
    });

    return (
      <Table
        disableSelect
        disableActionButtons
        disableSearch
        data={tableData}
        keyField={"managedDeviceId"}
        columns={[
          {
            dataField: "action",
            text: "Action Status",
            sort: true,
            formatter: (cell, row, rowIndex) => {
              let icon;
              switch (row.action) {
                case RESULT_STATUS_TYPE.WAITING:
                  icon = (
                    <span>
                      <i className="fas fa-circle" />
                    </span>
                  );
                  break;
                case RESULT_STATUS_TYPE.LOADING:
                  icon = <DotLoader size={18} color="#5e72e4" />;
                  break;
                case RESULT_STATUS_TYPE.FULFILLED:
                  icon = (
                    <span className="text-success">
                      <i className="fas fa-check-circle" />
                    </span>
                  );
                  break;
                default:
                  icon = (
                    <span className="text-warning">
                      <i className="fas fa-times-circle" />
                    </span>
                  );
                  break;
              }
              return icon;
            },
          },
          {
            dataField: "managedDeviceName",
            text: "Machine",
            sort: true,
          },
          {
            dataField: "userPrincipalName",
            text: "User",
            sort: true,
          },
        ]}
        onSelect={(row, isSelect) => {}}
        onSelectAll={(rows, isSelect) => {}}
        onAction={(string) => {}}
      />
    );
  };

  const getAuditTable = () => {
    console.log(`showing audit table`);
    // const results = map(actionResponse, "value");
    const tableData = actionResponse?.value || [];
    // ACTIVITYDATETIME
    // selectedIdSet.forEach((row) => {
    //   let action = RESULT_STATUS_TYPE.WAITING;
    //   if (wasActionCalled) {
    //     if (isEmpty(results)) {
    //       action = RESULT_STATUS_TYPE.LOADING;
    //     } else {
    //       action = RESULT_STATUS_TYPE.FULFILLED; // results?.[0]?.status;
    //     }
    //   }
    //   tableData.push({ ...row, action });
    // });

    return (
      <Table
        disableSelect
        disableActionButtons
        disableSearch
        data={tableData}
        keyField={"id"}
        columns={[
          {
            dataField: "displayName",
            text: "DisplayName",
            sort: true,
          },

          {
            dataField: "activityDateTime",
            text: "ActivityDateTime",
            sort: true,
          },
          {
            dataField: "activityResult",
            text: "ActivityResult",
            sort: true,
          },
          {
            dataField: "actor.applicationDisplayName",
            text: "Actor.applicationDisplayName",
            sort: true,
          },
          {
            dataField: "actor.userPrincipalName",
            text: "Actor.userPrincipalName",
            sort: true,
          },
          {
            dataField: "actor.ipAddress",
            text: "Actor.ipAddress",
            sort: true,
          },
          {
            dataField: "actor.remoteTenantId",
            text: "Actor.remoteTenantId",
            sort: true,
          },
          {
            dataField: "actor.remoteUserId",
            text: "Actor.remoteUserId",
            sort: true,
          },
          {
            dataField: "actor.type",
            text: "Actor.type",
            sort: true,
          },
          {
            dataField: "activity",
            text: "Activity",
            sort: true,
          },
        ]}
        onSelect={(row, isSelect) => {}}
        onSelectAll={(rows, isSelect) => {}}
        onAction={(string) => {}}
      />
    );
  };

  const runAction = (actionType) => {
    setWasActionCalled(true);
    const arrayOfIds = [];
    const arrayOfDeviceIds = [];
    selectedIdSet.forEach((pc) => {
      arrayOfIds.push(pc.id);
      arrayOfDeviceIds.push(pc.managedDeviceId);
    });
    const action = actionType || actionName;
    switch (action) {
      case ACTION_TYPE.REBOOT:
        try {
          const promises = [];
          arrayOfIds.forEach((id) => {
            promises.push(rebootCloudPc(auth.getAccessToken(), id));
          });

          const callApi = async () => {
            const result = await Promise.allSettled(promises);
            setActionResponse(result);
            setStatusMessage({ success: true, message: "Reboot finished" });
          };

          callApi();
        } catch (error) {
          setStatusMessage({
            success: false,
            message: "An error occurred, when rebooting devices",
          });
        }
        break;

      case ACTION_TYPE.REPROVISION:
        try {
          const promises = [];
          arrayOfIds.forEach((id) => {
            promises.push(reprovisionCloudPc(auth.getAccessToken(), id));
          });
          const callReprovision = async () => {
            const result = await Promise.allSettled(promises);
            setActionResponse(result);
            setStatusMessage({
              success: true,
              message: "Reprovisioning finished",
            });
          };
          callReprovision();
        } catch (error) {
          const result = map(arrayOfDeviceIds, (device) => ({
            status: RESULT_STATUS_TYPE.REJECTED,
            value: arrayOfDeviceIds.id,
          }));
          setActionResponse(result);
          setStatusMessage({
            success: true,
            message: "An error occurred when reprovisioning devices",
          });
        }
        break;

      case ACTION_TYPE.END_GRACE_PERIOD:
        try {
          const promises = [];
          arrayOfIds.forEach((id) => {
            promises.push(endCloudPcGracePeriod(auth.getAccessToken(), id));
          });

          const callApi = async () => {
            const result = await Promise.allSettled(promises);
            setActionResponse(result);
            setStatusMessage({
              success: true,
              message: "End grace period finished",
            });
          };

          callApi();
        } catch (error) {
          console.log(`error`, error);
          setStatusMessage({
            success: false,
            message: "An error occurred when ending grace period for devices",
          });
        }
        break;
      case ACTION_TYPE.TROUBLESHOOT:
        try {
          const promises = [];
          arrayOfIds.forEach((id) => {
            promises.push(troubleshootCloudPcs(auth.getAccessToken(), id));
          });

          const callApi = async () => {
            const result = await Promise.allSettled(promises);
            setActionResponse(result);
            setStatusMessage({
              success: true,
              message: "Troubleshoot finished",
            });
          };

          callApi();
        } catch (error) {
          console.log(`error`, error);
          setStatusMessage({
            success: false,
            message:
              "An error occurred when performing troubleshoot on devices",
          });
        }
        break;
      case ACTION_TYPE.AUDIT:
        console.log("running audit action");
        try {
          const promises = [];
          // arrayOfIds.forEach((id) => {
          //   promises.push(auditCloudPcs(auth.getAccessToken(), id));
          // });

          const callApi = async () => {
            const result = await auditCloudPcs(
              auth.getAccessToken(),
              arrayOfIds[0]
            );
            console.log(`result after calling auditcloud`, result);
            setActionResponse(result);
            setStatusMessage({
              success: true,
              message: "Audit finished",
            });
          };

          callApi();
        } catch (error) {
          console.log(`error`, error);
          setStatusMessage({
            success: false,
            message: "An error occurred when performing audit on device(s)",
          });
        }
        break;

      default:
        break;
    }
  };

  const getStatusMessage = () => {
    if (statusMessage === null) {
      return (
        <h3 className="modal-dialog text-info">
          Waiting might be longer with more devices
        </h3>
      );
    } else {
      return (
        <h3
          className={classnames(
            "modal-dialog",
            statusMessage.success ? "text-success" : "text-warn"
          )}
        >
          {statusMessage.message}
        </h3>
      );
    }
  };

  const closeModal = () => {
    wasActionCalled && refreshAllCloudPcs();
    setActionNameState(ACTION_TYPE.NOTHING);
    setShowModal(!showModal);
  };

  return (
    <>
      <Row>
        <div className="col">
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h1 className="mb-0">{title}</h1>
                </Col>
                <Col className="text-right" xs="6">
                  <Modal
                    className="modal-dialog-centered"
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                    size="lg"
                  >
                    <div className="modal-header">
                      <h3 className="modal-title" id="modal-title-default">
                        Are you sure?
                      </h3>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => closeModal()}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {actionName === ACTION_TYPE.AUDIT
                        ? getAuditTable()
                        : getSelectedIdsAndNames()}
                    </div>
                    <div className="modal-footer">
                      <Col className="mt--6">
                        <Row>{getStatusMessage()}</Row>
                        <Row>
                          <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              runAction();
                            }}
                            disabled={wasActionCalled}
                          >
                            {actionName}
                          </Button>
                        </Row>
                      </Col>
                    </div>
                  </Modal>
                </Col>
              </Row>
            </CardHeader>

            <Table
              data={tableData}
              keyField="managedDeviceId"
              columns={[
                {
                  dataField: "userPrincipalName",
                  text: "UserPrincipalName",
                  sort: true,
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true,
                },
                {
                  dataField: "managedDeviceName",
                  text: "ManagedDeviceName",
                  sort: true,
                },
                {
                  dataField: "displayName",
                  text: "DisplayName",
                  sort: true,
                },
                {
                  dataField: "imageDisplayName",
                  text: "ImageDisplayName",
                  sort: true,
                },
                {
                  dataField: "gracePeriodEndDateTime",
                  text: "GracePeriodEndDateTime",
                  sort: true,
                },
                {
                  dataField: "aadDeviceId",
                  text: "AadDeviceId",
                  sort: true,
                },
              ]}
              onSelect={(row, isSelect) => {
                if (isSelect) {
                  selectedIdSet.add(row);
                } else {
                  selectedIdSet.delete(row);
                }
              }}
              onSelectAll={(rows, isSelect) => {
                if (isSelect) {
                  setSelectedIdSet(new Set(rows));
                } else {
                  setSelectedIdSet(new Set());
                }
              }}
              onAction={(actionType) => {
                setActionResponse(null);
                setActionNameState(actionType);
                setWasActionCalled(false);
                if (actionType === ACTION_TYPE.AUDIT) {
                  console.log("running audit action");
                  runAction(actionType);
                }
                setShowModal(true);
              }}
            />
          </Card>
        </div>
      </Row>
    </>
  );
};

export default ManagementTable;
