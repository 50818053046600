import React, { useContext } from "react";
import { Button } from "reactstrap";

import AuthContext from "../auth/AuthContext";

import { DropdownItem } from "reactstrap";
import microsoftImage from "../assets/img/icons/common/microsoft.svg";

export function SignIn() {
  const authContext = useContext(AuthContext);
  const signIn = async () => {
    authContext.login();
  };

  return (
    <Button className="btn-neutral btn-icon" color="default" onClick={signIn}>
      <span className="btn-inner--icon mr-1">
        <img alt="Microsoft Icon" src={microsoftImage} />
      </span>
      <span className="btn-inner--text">Microsoft</span>
    </Button>
  );
}

export function SignOut(props) {
  const authContext = useContext(AuthContext);
  if (props.isFromNavBar) {
    return (
      authContext.user && (
        <DropdownItem onClick={() => authContext.logout()}>
          <i className="ni ni-user-run" />
          <span>Logout</span>
        </DropdownItem>
      )
    );
  }
  return (
    authContext.user && (
      <button
        className="btn btn-outline-secondary"
        onClick={() => authContext.logout()}
      >
        Sign Out User {props?.user?.uid ?? ""}
      </button>
    )
  );
}
