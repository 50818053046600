import React from "react";
import { Switch, Redirect } from "react-router-dom";

// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DashboardFooter from "../components/Footers/DashboardFooter";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";
import createRoutes from "../routes/createRoutes";

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.mainContent = React.createRef();
    this.state = {
      sidenavOpen: true,
    };
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.current.scrollTop = 0;
    }
  }

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/logo.png"),
            imgAlt: "logo",
          }}
        />
        <div
          className="main-content"
          ref={this.mainContent}
          onClick={this.closeSidenav}
        >
          <DashboardNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {createRoutes("/dashboard")}
            <Redirect from="*" to="/dashboard" />
          </Switch>
          <DashboardFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default DashboardLayout;
