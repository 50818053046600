import Login from "./views/pages/login/Login";

import Overview from "./w365/Overview";
import Deprovisioning from "./w365/Deprovisioning";
import Failed from "./w365/Failed";
import InGracePeriod from "./w365/InGracePeriod";
import NotProvisioned from "./w365/NotProvisioned";
import Provisioning from "./w365/Provisioning";
import Provisioned from "./w365/Provisioned";
import Upgrading from "./w365/Upgrading";

const routes = [
  {
    collapse: true,
    name: "Home",
    icon: "ni ni-shop text-primary",
    state: "homeCollapse",
    views: [
      {
        path: null,
        name: "Home",
        miniName: "H",
        component: Login,
        layout: "/",
        isExact: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Overview",
    icon: "ni ni-chart-pie-35 text-orange",
    state: "overviewCollapse",
    views: [
      {
        path: "/overview",
        name: "Overview",
        miniName: "O",
        component: Overview,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
    ],
  },
  {
    collapse: true,
    name: "Management",
    icon: "ni ni-folder-17 text-info",
    state: "managementCollapse",
    views: [
      {
        path: "/provisioned",
        name: "Provisioned",
        miniName: "P",
        component: Provisioned,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/in-grace-period",
        name: "In Grace Period",
        miniName: "G",
        component: InGracePeriod,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/provisioning",
        name: "Provisioning",
        miniName: "P",
        component: Provisioning,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/failed",
        name: "Failed",
        miniName: "F",
        component: Failed,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/deprovisioning",
        name: "Deprovisioning",
        miniName: "D",
        component: Deprovisioning,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/not-provisioned",
        name: "Not Provisioned",
        miniName: "N",
        component: NotProvisioned,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
      {
        path: "/upgrading",
        name: "Upgrading",
        miniName: "U",
        component: Upgrading,
        layout: "/dashboard",
        routeType: "AuthRoute",
      },
    ],
  },
];

export default routes;
