import { useContext } from "react";
import AuthContext from "../auth/AuthContext";

const AzureAuthCheck = (props) => {
  const authContext = useContext(AuthContext);
  if (authContext.isAuthenticated) {
    return props.children;
  } else {
    return props.fallback;
  }
};

export default AzureAuthCheck;
