import React from "react";
import { Switch, Redirect } from "react-router-dom";

import LandingNavbar from "../components/Navbars/LandingNavbar.js";
import LandingFooter from "../components/Footers/LandingFooter";

import createRoutes from "../routes/createRoutes";

class Landinglayout extends React.Component {
  constructor(props) {
    super(props);
    this.mainContent = React.createRef();
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.mainContent.current.scrollTop = 0;
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContent.current.scrollTop = 0;
    }
  }

  render() {
    return (
      <>
        <div className="main-content" ref={this.mainContent}>
          <LandingNavbar />
          <Switch>
            {createRoutes("/")}
            <Redirect from="*" to="/" />
          </Switch>
        </div>
        <LandingFooter />
      </>
    );
  }
}

export default Landinglayout;
