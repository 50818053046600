import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

class SimpleHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <Link to="/dashboard/overview">
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <div className="text-white d-inline-block mb-0">
                        {this.props.parentName}
                      </div>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                {this.props.disableButtons ? null : (
                  <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                    <Button className="btn-neutral" color="default" size="sm">
                      New
                    </Button>
                    <Button className="btn-neutral" color="default" size="sm">
                      Filters
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  disableButtons: PropTypes.bool,
};

export default SimpleHeader;
