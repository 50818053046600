export const statusTypes = {
  NOT_PROVISIONED: "notProvisioned",
  PROVISIONING: "provisioning",
  PROVISIONED: "provisioned",
  UPGRADING: "upgrading",
  IN_GRACE_PERIOD: "inGracePeriod",
  DEPROVISIONING: "deprovisioning",
  FAILED: "failed",
};

export const ACTION_TYPE = {
  REBOOT: "Reboot",
  REPROVISION: "Reprovision",
  END_GRACE_PERIOD: "End Grace Period",
  TROUBLESHOOT: "Troubleshoot",
  AUDIT: "Audit",
  NOTHING: "",
};

export const RESULT_STATUS_TYPE = {
  WAITING: "waiting",
  LOADING: "loading",
  FULFILLED: "fulfilled", // from Promise.allSettled response
  REJECTED: "rejected", // from Promise.allSettled response
};
