import React from "react";
import DotLoader from "react-spinners/DotLoader";

const Loader = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
      }}
    >
      <DotLoader color="#11cdef" />
    </div>
  );
};

export default Loader;
