import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import AzureAuthCheck from "../auth/AzureAuthCheck";

const AuthRoute = ({ component, path, key }: RouteProps) => {
  return (
    <AzureAuthCheck fallback={<Redirect to="/login" />}>
      <Route path={path} component={component} key={key}></Route>
    </AzureAuthCheck>
  );
};

export default AuthRoute;
