import React, { Suspense } from "react";

import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";

import withAuthProvider from "./auth/AuthProvider";
import AuthContext from "./auth/AuthContext";

import LandingLayout from "./layouts/LandingLayout";
import DashboardLayout from "./layouts/DashboardLayout";

import Loader from "./Loader";

function App(appProps) {
  return (
    <AuthContext.Provider
      value={{
        error: appProps.error,
        isAuthenticated: appProps.isAuthenticated,
        user: appProps.user,
        cloudPc: appProps.cloudPc,
        login: appProps.login,
        logout: appProps.logout,
        getAccessToken: appProps.getAccessToken,
        // setIsLoading: appProps.setIsLoading,
        callGetCloudPcsAndShowLoader: appProps.callGetCloudPcsAndShowLoader,
        isLoading: appProps.isLoading,
      }}
    >
      <BrowserRouter>
        <div>
          <main>
            <Suspense fallback={<Loader />}>
              {appProps.isLoading && <Loader />}
              <Switch>
                <Route
                  path="/dashboard"
                  render={(props) => (
                    <DashboardLayout {...props} {...appProps} />
                  )}
                />
                <Route
                  path="/"
                  render={(props) => <LandingLayout {...props} {...appProps} />}
                />
                <Redirect from="*" to="/" />
              </Switch>
            </Suspense>
          </main>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default withAuthProvider(App);
