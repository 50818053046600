import React from "react";
import { Route } from "react-router-dom";

import routesConfig from "../routes.js";
import AuthRoute from "./AuthRoute";

const getRoutes = (routes, filter: string) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views, filter);
    }
    if (prop.layout === filter) {
      const pathName = prop?.path ? prop.layout + prop.path : prop.layout;
      let ComponentType;
      switch (prop.routeType) {
        case "AuthRoute":
          ComponentType = AuthRoute;
          break;
        default:
          ComponentType = Route;
          break;
      }
      return (
        <ComponentType
          exact={prop.isExact}
          path={pathName}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

const createRoute = (filter: string = "/") => {
  return getRoutes(routesConfig, filter);
};

export default createRoute;
