import React from "react";

import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, Row } from "reactstrap";

import { chartOptions, parseOptions } from "../../variables/charts.js";

class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  render() {
    const { title, subTitle, options, data } = this.props;
    return (
      <>
        <div className="card-deck flex-column flex-xl-row">
          <Card>
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">
                    {title}
                  </h6>
                  {subTitle && <h2 className="h3 mb-0">{subTitle}</h2>}
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={data}
                  options={options}
                  className="chart-canvas"
                  id="chart-bars"
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default BarGraph;
