import React from "react";
import { Redirect } from "react-router-dom";

import NonAuthLogin from "./NonAuthLogin";
import AzureAuthCheck from "../../../auth/AzureAuthCheck";
const Login = () => {
  return (
    <AzureAuthCheck fallback={<NonAuthLogin />}>
      <Redirect to="/dashboard/overview" />
    </AzureAuthCheck>
  );
};

export default Login;
