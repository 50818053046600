import React from "react";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";

import AuthHeader from "../../../components/Headers/AuthHeader";
import { SignIn } from "../../../payment/Customers";

class NonAuthLogin extends React.Component {
  render() {
    return (
      <>
        <AuthHeader title="Welcome!" lead="Sign in to get started" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <SignIn isFromLoginScreen />
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NonAuthLogin;
