import React from "react";
import AuthContext from "../auth/AuthContext";

import { Container } from "reactstrap";
import SimpleHeader from "../components/Headers/SimpleHeader.js";
import { statusTypes } from "./utils/constants";
import ManagementTable from "./Components/ManagementTable";

const Provisioned = (props) => {
  const auth = React.useContext(AuthContext);
  const allCloudPcs = auth.cloudPc?.list?.value ?? [];

  const filterData = (status) =>
    allCloudPcs.filter((pc) => pc.status === status);

  const tableData = filterData(statusTypes.NOT_PROVISIONED);

  return (
    <>
      <SimpleHeader
        name="Not Provisioned"
        parentName="Management"
        disableButtons
      />
      <Container className="mt--6" fluid>
        <ManagementTable title={"Not Provisioned"} tableData={tableData} />
      </Container>
    </>
  );
};

export default Provisioned;
