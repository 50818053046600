import React from "react";

import countBy from "lodash/countBy";
import values from "lodash/values";
import mapKeys from "lodash/mapKeys";
import dayjs from "dayjs";
import { Container, Row, Col } from "reactstrap";

import OverviewHeader from "../components/Headers/OverviewHeader";
import LineGraph from "./Components/LineGraph";
import BarGraph from "./Components/BarGraph";

import InfoCard from "./Components/InfoCard";
import { colors } from "../variables/charts";
import AuthContext from "../auth/AuthContext";
import { statusTypes } from "./utils/constants";
import { useHistory } from "react-router-dom";

const Overview = (props) => {
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const allCloudPcs = auth.cloudPc?.list.value || [];

  const countsPerDayOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {},
        },
      ],
    },
  };

  const lastModifiedPerDayOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                //return '$' + value + 'k'
                return value;
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";
          if (data.datasets.length > 1) {
            content += label;
          }
          content += yLabel;
          return content;
        },
      },
    },
  };

  const filterPcs = (status) =>
    allCloudPcs.filter((pc) => pc.status === status);

  const totalCloudPcs = allCloudPcs.length;
  const numberOfProvisioned = filterPcs(statusTypes.PROVISIONED).length;
  const numberOfProvisioning = filterPcs(statusTypes.PROVISIONING).length;
  const numberOfInGrace = filterPcs(statusTypes.IN_GRACE_PERIOD).length;
  const numberOfNotProvisioned = filterPcs(statusTypes.NOT_PROVISIONED).length;

  const lastModifiedPerDayData = countBy(allCloudPcs, (pc) =>
    dayjs(pc.lastModifiedDateTime).format("YYYY-MM-DD")
  );

  const lastModifiedXLabel = values(lastModifiedPerDayData).reverse();
  const lastModifiedYLabel = [];
  for (const key in lastModifiedPerDayData) {
    lastModifiedYLabel.push(dayjs(key).format("MM/DD/YYYY"));
  }
  lastModifiedYLabel.reverse();

  const navigateToManagement = (status) => {
    let path;
    switch (status) {
      case statusTypes.PROVISIONED:
        path = "/dashboard/provisioned";
        break;
      case statusTypes.PROVISIONING:
        path = "/dashboard/provisioning";
        break;
      case statusTypes.IN_GRACE_PERIOD:
        path = "/dashboard/in-grace-period";
        break;
      case statusTypes.NOT_PROVISIONED:
        path = "/dashboard/not-provisioned";
        break;
      default:
        path = "/";
        break;
    }
    history.push(path);
  };

  return (
    <>
      <OverviewHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <InfoCard
              title="Provisioned"
              value={numberOfProvisioned}
              total={totalCloudPcs}
              color="primary"
              onSeeDetailsPress={() =>
                navigateToManagement(statusTypes.PROVISIONED)
              }
            />
          </Col>
          <Col md="6" xl="3">
            <InfoCard
              title="Provisioning"
              value={numberOfProvisioning}
              total={totalCloudPcs}
              color="info"
              onSeeDetailsPress={() =>
                navigateToManagement(statusTypes.PROVISIONING)
              }
            />
          </Col>
          <Col md="6" xl="3">
            <InfoCard
              title="In Grace Period"
              value={numberOfInGrace}
              total={totalCloudPcs}
              color="danger"
              onSeeDetailsPress={() =>
                navigateToManagement(statusTypes.IN_GRACE_PERIOD)
              }
            />
          </Col>
          <Col md="6" xl="3">
            <InfoCard
              title="Not Provisioned"
              value={numberOfNotProvisioned}
              total={totalCloudPcs}
              color="default"
              onSeeDetailsPress={() =>
                navigateToManagement(statusTypes.NOT_PROVISIONED)
              }
            />
          </Col>
        </Row>
        <LineGraph
          title="Deployment Activity"
          data={{
            labels: lastModifiedYLabel,
            datasets: [
              {
                label: "Count",
                data: lastModifiedXLabel,
                maxBarThickness: 10,
              },
            ],
          }}
          options={countsPerDayOptions}
        />
        {/* <BarGraph
          title="title"
          subTitle="sub title"
          data={{
            labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: "Performance",
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              },
            ],
          }}
          options={lastModifiedPerDayOptions}
        /> */}
      </Container>
    </>
  );
};
// }

export default Overview;
