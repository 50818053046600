import React from "react";

import { Card, CardBody, CardTitle, Progress, Row } from "reactstrap";
import classnames from "classnames";

type Props = {
  title: string,
  value: number,
  total: number,
  color: "primary" | "info" | "danger" | "default",
  onSeeDetailsPress: () => void,
};
const InfoCard = ({ title, value, total, color, onSeeDetailsPress }: Props) => {
  return (
    <Card className={classnames(`bg-gradient-${color}`, "primary border-0")}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0 text-white"
            >
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              {value}/{total}
            </span>
            <Progress
              className="progress-xs mt-3 mb-0"
              max={total}
              value={value}
              color="success"
            />
          </div>
        </Row>
        {!!onSeeDetailsPress && (
          <p className="mt-3 mb-0 text-sm">
            <a
              className="text-nowrap text-white font-weight-600"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                onSeeDetailsPress();
              }}
            >
              See details
            </a>
          </p>
        )}
      </CardBody>
    </Card>
  );
};

export default InfoCard;
