import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class DashboardFooter extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <Link className="font-weight-bold ml-1" to="/">
                    Cloud PC Management
                  </Link>
                </div>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default DashboardFooter;
