import React, { useContext } from "react";
import { DropdownToggle, Media } from "reactstrap";
import AuthContext from "../../../auth/AuthContext";

const Profile = () => {
  const authContext = useContext(AuthContext);

  const image = <i className="far fa-user"></i>;
  return (
    <DropdownToggle className="nav-link pr-0" color="" tag="a">
      <Media className="align-items-center">
        <span className="avatar avatar-sm rounded-circle">{image}</span>
        <Media className="ml-2 d-none d-lg-block">
          <span className="mb-0 text-sm font-weight-bold">
            {authContext?.user?.displayName}
          </span>
        </Media>
      </Media>
    </DropdownToggle>
  );
};

export default Profile;
